import * as React from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import Hero from '../../../components/heroSubpage'
import get from 'lodash/get'
import { kebabCase } from 'lodash'
import Card from '../../../components/card'

const TagsPage = (props, location) => {
  const posts = get(props, 'data.markdownRemark')
  const heroImage = get(props, 'data.heroImage')

  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-50"
          image={heroImage.childImageSharp.gatsbyImageData}
          alt="digital mind thinking powerfully"
          title="UNICOM Innovation Center"
          subtitle="Design & architect your digital transformation."
          type="blog"
        />

        <main>


          <section className="bg-white">
            <MDBContainer>
              <MDBRow className='mb-5'>
                <MDBCol>
                  <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-3"> {posts.frontmatter.section[1].title} </h3>
                  <div className="mt-1 pb-5 richtext divlink text-medium" dangerouslySetInnerHTML={{ __html: posts.frontmatter.section[1].description.replace('<a href="/innovation-center/tags/">digital transformation and innovation from across UNICOM Global</a>', 'digital transformation and innovation from across UNICOM Global'), }} />   
                </MDBCol>
              </MDBRow>

              <MDBRow>             
                {posts.frontmatter.section[1].feature.map( (features, findex) => {
                    return (
                      <Card
                        key={findex}
                        collg="3"
                        colmd="6"
                        height="2.0rem"
                        titleClass="font-alt font-w-700 letter-spacing-1 title-extra-small title-xs-medium mt-2"
                        title={features.title}
                        subtitle=""
                        description={features.description}
                        image={features.image.childImageSharp.gatsbyImageData}
                        alt={features.alttext}
                        link={features.link}
                        blogLink={true}
                      />
                    )
                  }
                )}
              </MDBRow>
            </MDBContainer>
          </section>
        </main>
      </div>
    </Layout>
  )
}
export default TagsPage

export function Head({ data, location }) {
  const heroImage = data.heroImage

  return (
    <SEO
      title={`UNICOM Innovation Center tags`}
      description={`Drill down into UNICOM Innovation Center blogs by blog category/tag`}
      url={location.pathname} 
      image={heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
      canonical={location.pathname}                  
    />
  )
}

export const tagPageQuery = graphql`
  query TagsListQuery {
    markdownRemark(
      frontmatter: { template: { eq: "other" }, title: { eq: "Lab" } }
    ) {
      frontmatter {
        meta {
          title
          description
        }
        title
        subtitle
        openingpara
        alttext
        image {
          childImageSharp {
            gatsbyImageData(width: 1980, quality: 90)
          }
        }
        section {
          title
          description
          feature {
            title
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            alttext
            description
            linktext
            link
          }
        }
      }
    }

    heroImage: file(name: { eq: "innovation-center-banner" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 90)
      }
    }
  }
`
