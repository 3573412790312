import React from 'react'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fat } from '@fortawesome/pro-thin-svg-icons'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import Card from '../components/card'

library.add(fat)

const CapabilitiesTemplate = ({ data }) => {
  const post = data.markdownRemark

  return (
    <div id="sub-page">
      <Layout>
        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          type="root"
          gradientClass="mask d-flex justify-content-center align-items-center gradient"
        />

        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol lg="12">
                <p className="font-w-400 text-xs-extra-large text-opening-para pb-md-4"> {post.frontmatter.openingpara} </p> 
              </MDBCol>
            </MDBRow>

            {post.frontmatter.headline != null &&
              post.frontmatter.headline.length > 0 && (
                <MDBRow className="pt-5">
                  <>
                    {post.frontmatter.headline.map((headlines, index) => {
                      return (
                        <Card
                          key={index}
                          collg="4"
                          colmd="6"
                          height="3.5rem"
                          title={headlines.title}
                          subtitle=""
                          description={headlines.linktext}
                          image={headlines.image.childImageSharp.gatsbyImageData}
                          alt={headlines.alttext}
                          link=""
                        />
                      )
                    })}
                  </>
                </MDBRow>
              )}
          </MDBContainer>
        </section>

        {post.frontmatter.section != null &&
          post.frontmatter.section.length > 0 && (
            <section className="bg-light-blue pb-5">
              <MDBContainer>
                <MDBRow>
                  <MDBCol>
                    <div
                      className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium pb-md-4 richtext"
                      dangerouslySetInnerHTML={{ __html: post.html }}
                    ></div>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </section>
          )}

        {post.frontmatter.section != null &&
          post.frontmatter.section.length > 0 && (
            <>
              {post.frontmatter.section.map((sections, index) => (
                <section className="bg-light-blue pt-3 pb-5" key={index}>
                  <MDBContainer>
                    <MDBRow>
                      <MDBCol md="4" className="pr-5">
                        <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium"> {sections.title} </h3> 
                        <p className="text-medium text-gray-dark mt-3"> {sections.description} </p> 
                      </MDBCol>

                      <MDBCol md="8">
                        <MDBRow>
                          {sections.feature.map((features, index) => (
                            <MDBCol
                              md="6"
                              className="pb-5 item-prods"
                              key={index}
                            >
                              <div className="feature-icon">
                                <FontAwesomeIcon
                                  icon={['fat', features.icon]}
                                  size="3x"
                                />
                              </div>
                              <p className="text-medium text-blue-dark font-w-700"> {features.title} </p> 
                              <p className="text-medium text-gray-dark">
                                {features.description}
                                {features.link && (
                                  <span className="text-small">
                                    {features.link.substring(0, 4) != 'http' ? (
                                      <Link to={features.link} className="effect" target="_blank"> {features.linktext} </Link>
                                     ) : (
                                      <a
                                        href={features.link}
                                        className="effect"
                                        target="_blank"
                                        rel="noopener"
                                      >
                                        {features.linktext}
                                      </a>
                                    )}
                                  </span>
                                )}
                              </p>
                            </MDBCol>
                          ))}
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </section>
              ))}
            </>
          )}

        {post.frontmatter.related != null &&
          post.frontmatter.related.length > 0 && (
            <section className="bg-white pt-5 pb-5">
              <MDBContainer>
                <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium"> Other capabilities </h3> <MDBRow>
                  {post.frontmatter.related.map((relate, index) => (
                    <MDBCol key={index} md="4" className="pt-2">
                      <Link to={relate.link} className="effect"> {relate.linktext} </Link> 
                      <FontAwesomeIcon className="pl-2 pt-3" icon={['fat', 'arrow-square-right']} /> 
                    </MDBCol>
                  ))}
                </MDBRow>
              </MDBContainer>
            </section>
          )}
      </Layout>
    </div>
  )
}
export default CapabilitiesTemplate

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "capabilities" } }
    ) {
      html
      frontmatter {
        meta {
          title
          description 
        }
        template
        title
        subtitle
        openingpara
        alttext
        image {
          childImageSharp {
            gatsbyImageData(width: 1980, quality: 90) 
          }
        }
        headline {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 90) 
            }
          }
          alttext
          linktext
          link
        }    
        section {
          title
          description
          linktext
          link
          feature {
            title
            icon
            description
            linktext
            link
          }
          placement
          alttext
          link
        }
        related {
          link
          linktext
        }
      }
    }
  }
`
