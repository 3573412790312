import React from 'react'
import { graphql } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import Layout from '../../components/layout'
import Hero from '../../components/heroSubpage'
import SEO from '../../components/seo'
import AltSection from '../../components/columnAlternatingStyled'
import Card from '../../components/card'

const Solutions = ({ data }) => {
  const post = data.markdownRemark
  const cardimage = data.cardimage

  return (
    <div id="sub-page">
      <Layout>
        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}           
          type="root"
          gradientClass="mask d-flex justify-content-center align-items-center gradient"
        />

        <section className="bg-white">
          <MDBContainer>
            <MDBRow className="pb-5">
              <MDBCol>
                <div className="font-w-400 text-xs-extra-large divlink text-opening-para" dangerouslySetInnerHTML={{__html: post.frontmatter.openingpara}} />
                <div className="mt-3 pb-5 richtext divlink" dangerouslySetInnerHTML={{ __html: post.html }} /> </MDBCol>
            </MDBRow>

            {post.frontmatter.section && (
              <>
                {post.frontmatter.section.map((sections, index) => {
                  return (
                    <div key={index}>
                      {/* UNICOM Digital solutions */}
                      {index === 0 ? (
                        <MDBRow className="pb-5">
                          <MDBCol>
                            <div className="about-overview">
                              {post.frontmatter.section.title && 
                              <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-extra-large-1 pb-5"> {post.frontmatter.section.title} </h3> 
                              }
                              <AltSection
                                key={index}
                                title={sections.title}
                                subtitle={sections.feature[0].title}
                                description={sections.feature[0].description}
                                image={cardimage.childImageSharp.gatsbyImageData}
                                alt="An example of a UniGW dashboard, used to digitally transform enterprise applications"
                                placement="right"
                                titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium text-very-dark-blue"
                                link={sections.feature[0].link}
                                colour="ficon-digital"
                              />
                            </div>
                          </MDBCol>
                        </MDBRow>
                      ) : (
                        <>
                          {/* Integrated solutions */}
                          <MDBRow>
                            <MDBCol className="pr-5">
                              <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium pt-5 pb-5"> {sections.title} </h3> 
                              <p className="text-medium text-gray-dark mt-3"> {sections.description} </p> 
                            </MDBCol>
                          </MDBRow>

                          <MDBRow>
                            {sections.feature.map((features, index) => (
                              <Card
                                key={index}
                                collg="6"
                                colmd="6"
                                height="3.5rem"
                                title={features.title}
                                subtitle=""
                                description={features.description}
                                image={features.image.childImageSharp.gatsbyImageData}
                                alt={features.alttext}
                                link={features.link}
                              />
                            ))}
                          </MDBRow>
                        </>
                      )}
                    </div>
                  )
                })}
              </>
            )}
          </MDBContainer>
        </section>
      </Layout>
    </div>
  )
}
export default Solutions

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}   
      canonical={location.pathname} 
    />   
  )
}

export const query = graphql`
  query {
    markdownRemark(
      frontmatter: { template: { eq: "other" }, title: { eq: "Solutions" } }
    ) {
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        ctatext
        ctaslug         
        openingpara
        alttext
        image {
          childImageSharp {
            gatsbyImageData(width: 1980, quality: 90) 
          }
        }
        section {
          title
          feature {
            title
          image {
            childImageSharp {
              gatsbyImageData(quality: 90) 
            }
          }
            alttext
            description
            linktext
            link
          }
        }
      }
      html
    }
    cardimage: file(name: { eq: "enterprise-application-modernization" }) {
      childImageSharp {
        gatsbyImageData(quality: 90) 
      }
    }
  }
`