import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import Link, { navigate } from 'gatsby-link'
import { MDBCol, MDBCard, MDBCardBody, MDBCardText } from 'mdbreact'
import moment from 'moment'

class Card extends React.Component {

  handleClick(value, link) {
    typeof window !== "undefined" && window.localStorage.setItem ("blog-category", JSON.stringify({category: value, expiry: moment(new Date(Date.now() + 3600000)).format("YYYY-MM-DD HH:mm:ss")}))
    // console.log(link)
    navigate(link === "blog" ? "/innovation-center/" : link)
  }

  render() {
    const colHeight = this.props.height != null ? this.props.height : "auto"

    return (
      <MDBCol
        lg={this.props.collg}
        md={this.props.colmd}
        className="pb-4 d-flex"
      >
        <MDBCard className="flex-fill">
          {this.props.link != null && this.props.link != '' ? (
            <>
              {this.props.link.substring(0, 4) != 'http' ? (
                <>
                  <a onClick={() => {this.handleClick(this.props.title, this.props.link)}}>
                    <GatsbyImage
                      top
                      image={this.props.image}
                      overlay="white-slight"
                      hover
                      waves
                      alt={this.props.alt}
                      className="rounded-top drop-shadow"
                    />
                  </a>

                </>
              ) : this.props.link != '' ? (
                <a href={this.props.link} target="_blank" rel="noopener">
                  <GatsbyImage
                    top
                    image={this.props.image}
                    overlay="white-slight"
                    hover
                    waves
                    alt={this.props.alt}
                    className="rounded-top drop-shadow"
                  />
                </a>
              ) : (
                <GatsbyImage
                  top
                  image={this.props.image}
                  overlay="white-slight"
                  hover
                  waves
                  alt={this.props.alt}
                  className="rounded-top drop-shadow"
                />
              )}
            </>
          ) : (
            <GatsbyImage
              top
              image={this.props.image}
              overlay="white-slight"
              hover
              waves
              alt={this.props.alt}
              className="rounded-top drop-shadow"
            />
          )}
          <MDBCardBody>
            <div style={{ maxHeight: colHeight, minHeight: colHeight }}>
              <h3
                className={
                  this.props.titleClass
                    ? this.props.titleClass
                    : 'font-alt font-w-700 letter-spacing-1 title-small title-xs-medium mt-2'
                }
              >
                {this.props.title}
              </h3>
              {this.props.subtitle != '' && this.props.subtitle != null && (
                <h4 className="font-alt font-w-400 letter-spacing-1 text-xs-medium text-medium mt-2">
                  {this.props.subtitle}
                </h4>
              )}
            </div>
            <hr />
            <MDBCardText className="font-w-400 text-medium mt-2 mb-2">
              {this.props.link != null && this.props.link != '' ? (
                <>
                  {this.props.link.substring(0, 4) != 'http' ? (
                    <>
                    <span onClick={() => {this.handleClick(this.props.title, this.props.link)}} href="#" className="effect" style={{cursor: "pointer"}}>{this.props.description}</span>
               {/*  <Link
                      to={
                        this.props.link.charAt(0) != '/'
                          ? '/' + this.props.link
                          : this.props.link
                      }
                      className="effect"
                    >
                      {this.props.description}
                    </Link> */}
                    </>
                  ) : this.props.link != '' ? (
                    <a
                      href={this.props.link}
                      className="effect"
                      target="_blank"
                      rel="noopener"
                    >
                      {this.props.description}
                    </a>
                  ) : (
                    <>{this.props.description}</>
                  )}
                </>
              ) : (
                <>{this.props.description}</>
              )}
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    )
  }
}

export default Card
